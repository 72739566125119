@charset "UTF-8";
/* FONT PATH -------------------------- */
@font-face { font-family: 'PayPlanPlus'; src: url("../../fonts/payplanplus.eot?v=1.0.0"); src: url("../../fonts/payplanplus.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../fonts/payplanplus.woff?v=1.0.0") format("woff"), url("../../fonts/payplanplus.ttf?v=1.0.0") format("truetype"), url("../../fonts/payplanplus.svg?v=1.0.0#payplanplus") format("svg"); font-weight: normal; font-style: normal; }
.ppp-icon { display: inline-block; font: normal normal normal 14px/1 PayPlanPlus; font-size: inherit; text-rendering: auto; speak: none; text-transform: none; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.ppp-lg { font-size: 1.33333333em; line-height: 0.75em; vertical-align: -15%; }

.ppp-2x { font-size: 2em; }

.ppp-3x { font-size: 3em; }

.ppp-4x { font-size: 4em; }

.ppp-5x { font-size: 5em; }

.ppp-fw { width: 1.28571429em; text-align: center; }

.ppp-ul { padding-left: 0; margin-left: 2.14285714em; list-style-type: none; }
.ppp-ul > li { position: relative; }

.ppp-li { position: absolute; left: -2.14285714em; width: 2.14285714em; top: 0.14285714em; text-align: center; }
.ppp-li.ppp-lg { left: -1.85714286em; }

.ppp-border { padding: .2em .25em .15em; border: solid 0.08em #eee; border-radius: .1em; }

.ppp-pull-left { float: left; }

.ppp-pull-right { float: right; }

.ppp.ppp-pull-left { margin-right: .3em; }
.ppp.ppp-pull-right { margin-left: .3em; }

/* Deprecated as of 4.4.0 */
.pull-right { float: right; }

.pull-left { float: left; }

.ppp.pull-left { margin-right: .3em; }
.ppp.pull-right { margin-left: .3em; }

.ppp-spin { -webkit-animation: ppp-spin 2s infinite linear; animation: ppp-spin 2s infinite linear; }

.ppp-pulse { -webkit-animation: ppp-spin 1s infinite steps(8); animation: ppp-spin 1s infinite steps(8); }

@-webkit-keyframes ppp-spin { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); transform: rotate(359deg); } }
@keyframes ppp-spin { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); transform: rotate(359deg); } }
.ppp-rotate-90 { -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)"; -webkit-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg); }

.ppp-rotate-180 { -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)"; -webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); transform: rotate(180deg); }

.ppp-rotate-270 { -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)"; -webkit-transform: rotate(270deg); -ms-transform: rotate(270deg); transform: rotate(270deg); }

.ppp-flip-horizontal { -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)"; -webkit-transform: scale(-1, 1); -ms-transform: scale(-1, 1); transform: scale(-1, 1); }

.ppp-flip-vertical { -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)"; -webkit-transform: scale(1, -1); -ms-transform: scale(1, -1); transform: scale(1, -1); }

:root .ppp-rotate-90, :root .ppp-rotate-180, :root .ppp-rotate-270, :root .ppp-flip-horizontal, :root .ppp-flip-vertical { filter: none; }

.ppp-stack { position: relative; display: inline-block; width: 2em; height: 2em; line-height: 2em; vertical-align: middle; }

.ppp-stack-1x, .ppp-stack-2x { position: absolute; left: 0; width: 100%; text-align: center; }

.ppp-stack-1x { line-height: inherit; }

.ppp-stack-2x { font-size: 2em; }

.ppp-inverse { color: #fff; }

.ppp-thin-envelope:before { content: ""; }

.ppp-thin-sms:before { content: ""; }

.ppp-thin-telephone:before { content: ""; }

.ppp-thin-textphone:before { content: ""; }

.ppp-thin-whatsapp:before { content: ""; }

.ppp-home:before { content: ""; }

.ppp-house:before { content: ""; }

.ppp-home-o:before { content: ""; }

.ppp-house-o:before { content: ""; }

.ppp-users:before { content: ""; }

.ppp-pie-chart:before { content: ""; }

.ppp-bank:before { content: ""; }

.ppp-university:before { content: ""; }

.ppp-car:before { content: ""; }

.ppp-cup:before { content: ""; }

.ppp-coffee:before { content: ""; }

.ppp-money:before { content: ""; }

.ppp-puzzle-piece:before { content: ""; }

.ppp-circle:before { content: ""; }

.ppp-square:before { content: ""; }

.ppp-minus-square:before { content: ""; }

.ppp-minus-circle:before { content: ""; }

.ppp-minus:before { content: ""; }

.ppp-mail-sent:before { content: ""; }

.ppp-inbox:before { content: ""; }

.ppp-send-o:before { content: ""; }

.ppp-send:before { content: ""; }

.ppp-bookmark-o:before { content: ""; }

.ppp-bookmark:before { content: ""; }

.ppp-paperclip:before { content: ""; }

.ppp-attachment:before { content: ""; }

.ppp-download:before { content: ""; }

.ppp-navicon:before { content: ""; }

.ppp-bars:before { content: ""; }

.ppp-credit-card-o:before { content: ""; }

.ppp-credit-card:before { content: ""; }

.ppp-pictures:before { content: ""; }

.ppp-warning:before { content: ""; }

.ppp-user-check:before { content: ""; }

.ppp-user-ticked:before { content: ""; }

.ppp-user-square:before { content: ""; }

.ppp-user-circle:before { content: ""; }

.ppp-user-o:before { content: ""; }

.ppp-profile-o:before { content: ""; }

.ppp-user:before { content: ""; }

.ppp-profile:before { content: ""; }

.ppp-textphone:before { content: ""; }

.ppp-star-half:before { content: ""; }

.ppp-star-o:before { content: ""; }

.ppp-star:before { content: ""; }

.ppp-send-receive:before { content: ""; }

.ppp-search-o:before { content: ""; }

.ppp-search:before { content: ""; }

.ppp-question-square:before { content: ""; }

.ppp-question-circle:before { content: ""; }

.ppp-question:before { content: ""; }

.ppp-power-square:before { content: ""; }

.ppp-power-circle:before { content: ""; }

.ppp-power:before { content: ""; }

.ppp-plus-square:before { content: ""; }

.ppp-add-square:before { content: ""; }

.ppp-plus-circle:before { content: ""; }

.ppp-add-circle:before { content: ""; }

.ppp-plus-o:before { content: ""; }

.ppp-add-o:before { content: ""; }

.ppp-plus:before { content: ""; }

.ppp-add:before { content: ""; }

.ppp-phone:before { content: ""; }

.ppp-pencil-square:before { content: ""; }

.ppp-edit-square:before { content: ""; }

.ppp-pencil-circle:before { content: ""; }

.ppp-edit-circle:before { content: ""; }

.ppp-pencil:before { content: ""; }

.ppp-edit:before { content: ""; }

.ppp-folder-open-o:before { content: ""; }

.ppp-folder-open:before { content: ""; }

.ppp-folder-o:before { content: ""; }

.ppp-folder:before { content: ""; }

.ppp-mail-reply:before { content: ""; }

.ppp-mail-forward:before { content: ""; }

.ppp-mail-o:before { content: ""; }

.ppp-envelope-o:before { content: ""; }

.ppp-mail:before { content: ""; }

.ppp-envelope:before { content: ""; }

.ppp-lock-o:before { content: ""; }

.ppp-lock:before { content: ""; }

.ppp-key:before { content: ""; }

.ppp-info-square:before { content: ""; }

.ppp-info-circle:before { content: ""; }

.ppp-info:before { content: ""; }

.ppp-floppy-disc-o:before { content: ""; }

.ppp-save-o:before { content: ""; }

.ppp-save:before { content: ""; }

.ppp-floppy-disc:before { content: ""; }

.ppp-file-compose:before { content: ""; }

.ppp-file-tick:before { content: ""; }

.ppp-file-laptop:before { content: ""; }

.ppp-file-add:before { content: ""; }

.ppp-file:before { content: ""; }

.ppp-eye:before { content: ""; }

.ppp-eye-o:before { content: ""; }

.ppp-exclamation-triangle-o:before { content: ""; }

.ppp-warning-o:before { content: ""; }

.ppp-caution-o:before { content: ""; }

.ppp-exclamation-triangle:before { content: ""; }

.ppp-warning2:before { content: ""; }

.ppp-caution:before { content: ""; }

.ppp-exclamation-square:before { content: ""; }

.ppp-exclamation-circle:before { content: ""; }

.ppp-exclamation:before { content: ""; }

.ppp-file-complete-o:before { content: ""; }

.ppp-file-complete:before { content: ""; }

.ppp-comment-o:before { content: ""; }

.ppp-comment:before { content: ""; }

.ppp-cog-o:before { content: ""; }

.ppp-cog:before { content: ""; }

.ppp-chevron-left-circle:before { content: ""; }

.ppp-chevron-left:before { content: ""; }

.ppp-chevron-down-circle:before { content: ""; }

.ppp-chevron-down:before { content: ""; }

.ppp-chevron-right-circle:before { content: ""; }

.ppp-chevron-right:before { content: ""; }

.ppp-chevron-up-circle:before { content: ""; }

.ppp-chevron-up:before { content: ""; }

.ppp-cross-circle:before { content: ""; }

.ppp-remove-circle:before { content: ""; }

.ppp-times-circle:before { content: ""; }

.ppp-close-circle:before { content: ""; }

.ppp-cross:before { content: ""; }

.ppp-remove:before { content: ""; }

.ppp-times:before { content: ""; }

.ppp-close:before { content: ""; }

.ppp-check-circle:before { content: ""; }

.ppp-check:before { content: ""; }

.ppp-tick:before { content: ""; }

.ppp-complete:before { content: ""; }

.ppp-camera:before { content: ""; }

.ppp-calendar:before { content: ""; }

.ppp-arrow-left-circle:before { content: ""; }

.ppp-arrow-left:before { content: ""; }

.ppp-arrow-down-circle:before { content: ""; }

.ppp-arrow-down:before { content: ""; }

.ppp-arrow-right-circle:before { content: ""; }

.ppp-arrow-right:before { content: ""; }

.ppp-arrow-up-circle:before { content: ""; }

.ppp-arrow-up:before { content: ""; }

.sr-only { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus { position: static; width: auto; height: auto; margin: 0; overflow: visible; clip: auto; }
